import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  writeBatch,
} from 'firebase/firestore';
import type { TransactionRule, OptionalField } from '@og-shared/types';
import { firestoreDeleteDocumentsInBatches } from './utils';

export function firestoreUpdateRule(groupId: string, update: TransactionRule) {
  const path = `groups/${groupId}/rules/${update.rule_id}`;
  const docRef = doc(getFirestore(), path);
  return setDoc(docRef, update, { merge: true });
}

export async function firestoreCreateRule(
  groupId: string,
  rule: OptionalField<TransactionRule, 'rule_id'>
) {
  const collectionPath = `groups/${groupId}/rules/`;
  const newDocRef = doc(collection(getFirestore(), collectionPath));
  const savedRule: TransactionRule = {
    ...rule,
    rule_id: newDocRef.id,
  };
  const docPath = `${collectionPath}${savedRule.rule_id}`;
  const docRef = doc(getFirestore(), docPath);
  await setDoc(docRef, savedRule, { merge: true });
  return savedRule;
}

export function firestoreListenToGroupRules(
  groupId: string,
  callback: (rules: TransactionRule[]) => void
) {
  const path = `groups/${groupId}/rules`;
  const collectionRef = collection(getFirestore(), path);
  const key: keyof TransactionRule = 'order';
  const q = query(collectionRef, orderBy(key, 'asc'));
  return onSnapshot(q, querySnapshot => {
    const docs = querySnapshot.docs.map(doc => doc.data() as TransactionRule);
    callback(docs);
  });
}

export async function firestoreGetRules(groupId: string) {
  const path = `groups/${groupId}/rules`;
  const collectionRef = collection(getFirestore(), path);
  const key: keyof TransactionRule = 'order';
  const q = query(collectionRef, orderBy(key, 'asc'));
  const snap = await getDocs(q);
  const docs = snap.docs.map(doc => doc.data() as TransactionRule);
  return docs;
}

export function firestoreDeleteRule(groupId: string, ruleId: string) {
  const path = `groups/${groupId}/rules/${ruleId}`;
  const docRef = doc(getFirestore(), path);
  return deleteDoc(docRef);
}

export async function firestoreDeleteAllRules(groupId: string) {
  const rules = await firestoreGetRules(groupId);
  const docRefs = rules.map(rule => {
    const path = `groups/${groupId}/rules/${rule.rule_id}`;
    const docRef = doc(getFirestore(), path);
    return docRef;
  });
  return firestoreDeleteDocumentsInBatches(docRefs);
}

export function firestoreReorderRules(
  groupId: string,
  rules: Pick<TransactionRule, 'rule_id' | 'order'>[]
) {
  const batch = writeBatch(getFirestore());
  for (const [index, rule] of rules.entries()) {
    const path = `groups/${groupId}/rules/${rule.rule_id}`;
    const docRef = doc(getFirestore(), path);
    batch.update(docRef, { order: index });
  }
  return batch.commit();
}
